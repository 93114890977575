import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { Link, BrowserRouter, Route, Routes } from "react-router-dom";
import GuestForm from "./components/GuestForm";
import Profile from "./components/Profile";
import Profile2 from "./components/Profile2";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<GuestForm />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route path=":id" element={<Profile2 />}>
              
            </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
