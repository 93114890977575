import React from 'react'

export default function GuestForm()  {
    return (
<section className="h-100 h-custom" >
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-lg-8 col-xl-6">
        <div className="card rounded-3">
          <img src="https://wng-public-images.s3.us-west-1.amazonaws.com/profile2.jpg" alt="Sample photo"/>
          <div className="card-body p-4 p-md-5">
            <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">Self Registration Info</h3>
            <h4 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">test test</h4>
            <form className="px-md-2">

              <div className="form-outline mb-4">
                <input type="text" id="form3Example1q" className="form-control" />
                <label className="form-label" htmlFor="form3Example1q">Name</label>
              </div>

              <div className="row">

                <div className="col-md-6 mb-4">
                <label className="form-label" htmlFor="form3Example1q"> Guests</label>
                  <select className="select">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  
                </div>
              </div>



              <div className="row mb-4 pb-2 pb-md-0 mb-md-5">
                <div className="col-md-6">

                  <div className="form-outline">
                    <input type="text" id="form3Example1w" className="form-control" />
                    <label className="form-label" htmlFor="form3Example1w">Phone Number</label>
                  </div>

                </div>
              </div>

              <button type="submit" className="btn btn-success btn-lg mb-1">Submit</button>

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      );
}
